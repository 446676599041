html {
    scroll-behavior: smooth;
}

body {
    font-family: "Montserrat", serif;
}

input[type="submit"] {
    cursor: pointer;
}

.site-logo img {
    max-width: 200px;
}

.container {
    margin: auto;
    max-width: 1200px;
}

.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

a.button {
    border-radius: 5px;
    margin: 0 auto 0;
    display: inline-block;
    padding: 20px 80px;
    background: #0088a5;
    text-decoration: none;
    text-transform: uppercase;
    color: white;

    max-width: 150px;
}

a.button:hover {
    background: #2a2d40;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 22px;
}

p {
    font-size: 16px;
}

header {
    padding: 20px 100px;
    background: #2a2d40;
    display: flex;
    justify-content: space-between;
}

.nav ul {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nav ul li {
    height: 30px;
    display: inline-block;

    border-radius: 5px;
    padding: 10px;
}

.nav ul.mainmenu > li:last-child {
    background: #008fa8;
}

.nav ul.mainmenu > li:last-child:hover a {
    color: #2a2d40;
}

.nav ul.mainmenu > li:hover > a {
    color: #008fa8;
}

.nav ul li:not(:last-child) {
    margin-right: 20px;
}

.nav ul li a {
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

/*
.nav ul li a:hover {
 color: #2bc0c1;
}
*/

.nav ul.mainmenu > li:hover > ul.submenu {
    display: block;
}

.nav ul.submenu {
    position: absolute;
    z-index: 1;
    display: none;
    margin-top: 10px;
    height: auto;
    background: #2a2d40;
}

.nav ul.submenu li {
    display: block;
    border-top: 1px solid white;
    border-radius: 0px;
    margin-right: 0px;
}

.nav ul.submenu > li a {
    color: white;
}

.nav ul.submenu > li a:hover {
    color: #008fa8;
}

.block {
    display: flex;
    justify-content: space-between;
    line-height: 1.7;
    margin-bottom: 80px;
}
.block-left {
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
}

.block-left p {
    padding-left: 40px;
}

.block-right {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 40px;
    text-align: left;
}

#slider {
    background: #f8f7f8;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.slider-text {
    /* margin-left: 150px; */
    display: flex;
    margin-right: 250px;
    text-align: right;
    justify-content: center;
    flex-direction: column;
    max-width: 350px;
    align-items: flex-end;
}

/*
.slider-text h1 {
    font-size: 36px;
    font-weight: bold;
}
*/

.slider-text a.button {
    margin: 0;
}

.ecommerce a.button {
    margin-top: 25px;
    background: #000033;
}

.ecommerce a.button:hover {
    background: #008fa8;
}

.ecommerce h1 {
    font-size: 36px;
}

.ecommerce .slider-text {
    max-width: 450px;
}

.offer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.offer__left {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
}

.offer__left div.offer-wrap {
    width: 50%;
    box-sizing: border-box;
    padding: 20px;
}

.offer-__left div.offer-inner {
    border: 1px solid #e8e1e1;
    box-sizing: border-box;
    padding: 40px 30px;
    height: 100%;
}

.offer__left a.button {
    padding: 20px 40px;
}

.offer__right {
    padding-top: 5%;
}

.intro {
    position: relative;
    padding: 130px 0 150px;
    background: #f8f7f8;
    margin-bottom: 150px;
}

.intro__left {
    height: 100%;
    top: 0;
    position: absolute;
    left: 0;
}

.intro__right {
    height: 100%;
    position: absolute;
    transform: rotate(180deg);
    top: 0;
    right: 0;
}

.intro p {
    margin-bottom: 50px;
}

.intro__inner {
    max-width: 600px;
    margin: auto;
}

.intro__buttons {
    display: flex;
}

#portfolio {
    margin-bottom: 150px;
    position: relative;
}

.portfolio-nav ul {
    text-transform: uppercase;
    display: flex;
    list-style: none;
    justify-content: flex-end;
}
.portfolio-nav ul li {
    font-weight: 500;
    margin-left: 20px;
    cursor: pointer;

    padding: 2px 10px 3px;
}

.portfolio-nav ul li.active {
    color: #0088a5;
    border: 2px solid #0088a5;
}

.portfolio-inner {
    display: flex;
    flex-wrap: wrap;
}

.portfolio-item {
    margin: 10px;
    background: #f8f7f8;
    padding: 30px 20px;
    width: calc(1 / 3 * 100% - 60px);
}

.portfolio-item img {
    width: 100%;
    cursor: pointer;
}

.portfolio-desc {
    display: none;
    background: white;
    position: absolute;
    top: 30px;
    left: 30px;
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    text-align: left;
    padding: 30px 30px;
}

.portfolio-item:hover .portfolio-desc {
    display: block;
}

#techno {
    margin-bottom: 150px;
    position: relative;
    background: #bfbcbf;
    padding: 20px 0px 50px;
    color: white;
}

.techno-left {
    position: absolute;
    top: -25%;
    left: 0;
}
.techno-right {
    position: absolute;
    top: -30%;
    right: 0;
}

.techno-nav ul {
    display: flex;
    list-style: none;
    justify-content: center;
}

.techno-nav ul li {
    cursor: pointer;
    font-size: 18px;
    margin: 0 5px;
    padding-right: 10px;
}

.techno-nav ul li:not(:last-child) {
    border-right: 2px solid white;
}

.techno-inner {
    display: flex;
    flex-wrap: wrap;
}

.techno-inner .techno-item {
    margin: 20px 20px;
    width: calc(1 / 6 * 100% - 40px);
}

.techno-inner .techno-item img {
    max-width: 100%;
}

.contacts {
    display: flex;
    justify-content: center;
    margin-bottom: 150px;
}

.contacts__item {
    border: 1px solid #e8e1e1;
    margin: 20px 20px 0 0;
    width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
    padding: 30px 20px;
}

#contact-form {
    position: relative;
    color: white;
    background: #222c42;
    max-width: 760px;
    margin: auto;
    margin-bottom: 150px;
}

#contact-form form {
    position: relative;
    z-index: 2;
    padding: 30px 30px 70px;
    background: #222c42;
}

#contact-form input[type="text"] {
    background: #222c42;
    border: none;
    color: white;
    height: 30px;
    border-bottom: 1px white solid;
}

#contact-form ::placeholder {
    color: white;
}

#contact-form input[type="submit"] {
    display: inline-block;
    padding: 10px 40px;
    background: #0088a5;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    border: none;
    max-width: 150px;
}

#contact-form .form-inner {
    display: flex;
    flex-wrap: wrap;
}

#contact-form .form-inner h3,
#contact-form .form-inner p,
#contact-form .form-inner input[type="text"] {
    width: calc(1 / 2 * 100% - 50px);
    margin-right: 50px;
    margin-bottom: 50px;
}

.contact-left {
    z-index: 0;
    top: 0;
    position: absolute;
    left: -25%;
}

.contact-right {
    z-index: 0;
    top: 0;
    position: absolute;
    right: -25%;
}
/*

#contact-form:before {
    content: " ";
    position:absolute;
    background-color: red;
    width:100px;
    height: 100px;
}

#contact-form:after {
    content: " ";
}

*/

.contact-page {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
}
.contact-page .contact-form {
    margin-bottom: 0;
}

footer {
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    background: #2a2d40;
    color: white;
}

footer .logo {
    max-width: 200px;
}
footer > div {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    justify-content: flex-end;
}

footer .footer-4 {
    justify-content: space-evenly;
}

footer .social-menu {
    flex-direction: row;
}
footer .social-menu img {
    margin-right: 5px;
}

.tab-content {
    display: flex;
    flex-wrap: wrap;
}

.tab-list {
    padding-left: 0;
}

.tab-list li {
    cursor: pointer;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    background-color: white;
}

.project__desc {
    max-width: 800px;
    background: white;
    position: fixed;
    top: 29px;
    left: 33%;
    box-shadow: 0px -1px 19px rgb(0 0 0 / 25%);
    z-index: 5;

    height: 100vh;
    overflow: auto;
    border-radius: 20px;
}

.desc__inner {
    position: relative;
    z-index: 8;
    background: white;

    height: auto;
    padding: 20px 10px;
    border-radius: 20px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    z-index: 10;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 768px) {
    .header {
        flex-wrap: wrap;
        justify-content: center;
    }

    .nav ul {
        flex-wrap: wrap;
        justify-content: center;
    }

    .contacts {
        flex-wrap: wrap;
    }
    .contacts__item {
        width: 100%;
    }

    .offer {
        flex-direction: column;
    }

    .offer__right,
    .offer__left {
        width: 100%;
    }

    .offer__left div.offer-wrap {
        width: 100%;
    }

    .techno-nav ul {
        flex-direction: column;
    }

    .intro__left,
    .intro__right {
        width: 100px;
        height: auto;
    }

    .intro__inner {
        width: calc(100% - 200px);
    }

    .intro__buttons {
        flex-wrap: wrap;
    }

    #slider {
        flex-direction: column;
    }

    #slider img {
        width: 100%;
    }

    .slider-text {
        margin: auto;
        text-align: center;
        padding-bottom: 40px;
    }

    .block {
        flex-direction: column;
    }
    .block-left,
    .block-right {
        margin: auto;
        width: 100% !important;
        padding-left: 0px;
    }

    .block-left p,
    .block-right p {
        padding: 0px 20px;
    }

    .no-mobile {
        display: none;
    }

    footer {
        flex-direction: column;
        align-items: center;
    }
}

@-webkit-keyframes fadeInRight2 {
    0% {
        opacity: 0;
        /* -webkit-transform: translate3d(100%, 0, 0); */
        /* transform: translate3d(100%, 0, 0); */
    }
    to {
        opacity: 1;
        /*-webkit-transform: translateZ(0);*/
        /*transform: translateZ(0);*/
    }
}
@keyframes fadeInRight2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.fadeInRight2,
.fadeInLeft2,
.fadeInUp,
.fadeIn,
.zoomIn {
    visibility: hidden;
}
.animate.fadeInRight2 {
    -webkit-animation-name: fadeInRight2;
    animation-name: fadeInRight2;
    animation-duration: 1s;
    visibility: visible;
}

@-webkit-keyframes fadeInLeft2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInLeft2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate.fadeInLeft2 {
    -webkit-animation-name: fadeInLeft2;
    animation-name: fadeInLeft2;
    animation-duration: 1s;
    visibility: visible;
}

@-webkit-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn2 {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.animate.fadeIn2 {
    -webkit-animation-name: fadeIn2;
    animation-name: fadeIn2;
    animation-duration: 1s;
    visibility: visible;
}

@-webkit-keyframes fadeInUp2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInUp2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate.fadeInUp2 {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;

    animation-duration: 1s;
    visibility: visible;
}

@-webkit-keyframes zoomIn2 {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
@keyframes zoomIn2 {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
.animate.zoomIn2 {
    -webkit-animation-name: zoomIn2;
    animation-name: zoomIn2;

    animation-duration: 1s;
    visibility: visible;
}
